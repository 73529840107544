import React from 'react';
import { Link } from 'gatsby';

import Layout from '../../Layout/component';
import { getPageUrl } from '../../Routes';

const RandomLetter = () => (
  <Layout routeSlug="RandomLetterGame">
    <h1>Hi people</h1>
    <p>Welcome to your new Gatsby site.</p>
    <p>Now go build something great.</p>
    <Link to={getPageUrl('NotFound')}>Go to 404</Link>
  </Layout>
);

export default RandomLetter;
